.pb-60 {
  padding-bottom: 4rem;
}

.ptb-60 {
  padding-bottom: 4rem;
  padding-top: 4rem;
}

#auction-detail {
  h2 {
    font-size: 30px;
    font-weight: bold;
  }
  .state-title {
    font-weight: 300;
    margin-right: 0.25rem;
    color: #929292;
  }
  .state-content {
    text-transform: uppercase;
    color: var(--secondary-background-color);
  }
  .date-from-to {
    margin-top: 1rem;
    margin-bottom: 1rem;
    background: #f0f0f0;
    padding: 1rem 1.5rem;
    border-radius: 0.75rem;
    font-size: 20px;
    color: #929292;
  }
}

.card-lot {
  margin-bottom: 1rem;
  .product-image {
    position: relative;
    img {
      width: 100%;
    }
  }
  button {
    border-radius: 0%;
    width: 100%;
    font-size: 13px;
    font-weight: bold;
    height: 40px;
  }
  .title-lot {
    margin-bottom: 0.1rem;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    font-weight: bold;
    font-size: 18px;
    padding: 0rem 0.5rem 0rem 0.5rem;
  }
  .id-lot {
    margin-bottom: 0px;
    font-size: 20px;
    padding: 0.5rem 0.5rem 0rem 0.5rem;
  }
  .quantity-lot {
    margin-bottom: 0px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    font-size: 16px;
    padding: 0rem 0.5rem;
  }
  .description-without {
    margin-bottom: 0px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    font-size: 16px;
    padding: 0rem 0.5rem;
  }
  .description-with {
    height: 25px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    margin-bottom: 0;
    font-size: 16px;
    padding: 0rem 0.5rem;
  }
  .date-close-and-price {
    margin-bottom: 0px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    font-size: 16px;
    padding: 0rem 0.5rem;
  }
  .best-offer-user {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    padding: 0rem 0.5rem;
  }
  .actual-offer {
    margin-bottom: 0rem;
    font-size: 16px;
    padding: 0rem 0.5rem;
  }
  .winning {
    background-color: var(--button-offer-winning) !important;
    border-color: var(--button-offer-winning) !important;
  }
  .not_offered {
    background-color: var(--button-offer-not_offered) !important;
    border-color: var(--button-offer-not_offered) !important;
    color: var(--text-color-with-secondary) !important;
  }
  .not_auctioned {
    background-color: var(--button-offer-not_auctioned) !important;
    border-color: var(--button-offer-not_auctioned) !important;
  }
  .auctioned {
    background-color: var(--button-offer-auctioned) !important;
    border-color: var(--button-offer-auctioned) !important;
  }
}

.timeclock {
  background: #df0404;
  text-align: center;
  padding: 0.2rem;
  animation: changeColor 1s infinite;
  div {
    color: white;
    font-weight: bold;
  }
}

.auction-in-proccess {
  width: max-content;
  animation: zoominoutsinglefeatured 1s infinite;
  max-width: 100%;
}

@keyframes changeColor {
  0% {
    background: #a10000;
  }
  50% {
    background: #df0404;
  }
  100% {
    background: #a10000;
  }
}

@keyframes zoominoutsinglefeatured {
  0% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(1.1, 1.1);
  }
  100% {
    transform: scale(1, 1);
  }
}

.Toastify__progress-bar--default {
  background: var(--secondary-background-color) !important;
}

.description-auction {
  p {
    font-size: 16px !important;
  }
}

.description-lots {
  font-size: 16px !important;
  p {
    font-size: 16px !important;
  }
}

.item-myaccount {
  margin-left: 2rem;
  cursor: pointer;
}

@media screen and (max-width: 990px) {
  .item-myaccount {
    margin-left: 0.5rem;
  }
}
