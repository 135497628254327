@import "~react-image-gallery/styles/scss/image-gallery.scss";
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600;700&display=swap");

p,
span,
h1,
h2,
h3,
h4,
h5,
label,
div {
  font-family: var(--family-font) !important;
}

.section {
  padding-top: 4rem;
  padding-bottom: 4rem;
}

.color-secondary {
  color: var(--secondary-background-color);
}

.faq-accordion {
  margin-top: 2rem;
  .accordion {
    padding: 0rem;
  }
}

.contact-list {
  list-style-type: none;
  padding-left: 0;
  margin: {
    top: 20px;
    bottom: 25px;
  }
  li {
    color: #666666;
    margin-bottom: 10px;
    font-size: 13px;
    position: relative;
    padding-left: 20px;

    i {
      color: #000;
      position: absolute;
      left: 0;
      top: 2px;
    }
    a {
      color: #666666;
      display: inline-block;

      &:hover,
      &:focus {
        color: #000;
      }
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
}

.whatsapp {
  position: fixed;
  width: 3.5rem;
  height: 3.5rem;
  bottom: 1rem;
  right: 4rem;
  background-color: #25d366;
  color: #fff !important;
  border-radius: 3rem;
  text-align: center;
  font-size: 2.5rem;
  z-index: 100;
}

.desktop {
  visibility: visible;
}

.mobile {
  display: none;
}

.go-top {
  position: fixed;
  cursor: pointer;
  bottom: 25px;
  right: 15px;
  color: var(--text-color-with-secondary);
  background-color: var(--secondary-background-color);
  z-index: 4;
  width: 40px;
  text-align: center;
  height: 40px;
  line-height: 40px;
  border-radius: 100%;

  i {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    right: 0;
    margin: 0 auto;

    &:last-child {
      opacity: 0;
      visibility: hidden;
      top: 60%;
    }
  }
  &:hover,
  &:focus {
    i {
      &:first-child {
        opacity: 0;
        top: 0;
        visibility: hidden;
      }
      &:last-child {
        opacity: 1;
        visibility: visible;
        top: 50%;
      }
    }
  }
}

@media screen and (max-width: 990px) {
  .desktop {
    display: none !important;
  }
  .mobile {
    display: block;
  }
  .whatsapp {
    width: 2.5rem !important;
    height: 2.5rem !important;
    bottom: 10px !important;
    left: 10px !important;
    font-size: 1.8rem !important;
  }
  .go-top {
    bottom: 10px;
    right: 10px;
    width: 35px;
    height: 35px;
    line-height: 35px;
  }
  .tab-content {
    .tab-pane {
      width: 100%;
      .row {
        width: 100%;
        margin: 0px;
      }
    }
  }
}
