.facebook-button-class {
  padding: 0.2rem 2rem;
  background: #1876f2;
  border: white;
  color: white;
  border: 1px solid transparent;
  font-family: "TrebuchetMS", "Trebuchet MS";
  i {
    margin-right: 8px;
  }
}

.facebook-button-class:hover {
  background: transparent;
  border: 1px solid #1876f2;
  color: #1876f2;
}

.desktop-login {
  visibility: visible;
}

.mobile-login {
  display: none;
}

@media screen and (max-width: 990px) {
  .desktop-login {
    display: none;
  }
  .mobile-login {
    display: contents;
  }
}

.nav-tabs-custom-login {
  width: 49%;
  text-align: center;
}

.new-customer-content {
  button {
    background: var(--primary-background-color);
    color: #ffffff;
    font-size: 12px;
    font-weight: bold;
    text-transform: uppercase;
    padding: 0.75rem 1rem;
  }
  button:hover {
    color: #ffffff;
  }
}

.login-content {
  .login-form {
    background: #f0f0f0;
    padding: 2rem;
    border-radius: 0.75rem;
    button {
      background: var(--secondary-background-color);
      color: var(--text-color-with-secondary);
      font-size: 12px;
      font-weight: bold;
      text-transform: uppercase;
      padding: 0.75rem 1rem;

      @media screen and (max-width: 990px) {
        padding: 0.5rem 1rem;
        width: 100%;
        margin-bottom: 1rem;
      }
    }
    .forgot-password {
      font-size: 14px;
      text-decoration: underline;
      color: var(--primary-background-color);
    }
    .form-group {
      input {
        border-radius: 0.45rem;
        border: none;
        background: #e1e1e1;
      }
    }
  }
}
